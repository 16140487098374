/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  Heading,
  Flex,
  Text,
  CommunityIcon,
  SwapIcon
} from '@pancakeswap/uikit'
import { useTranslation } from 'contexts/Localization'
import useTheme from 'hooks/useTheme'
import useIntersectionObserver from 'hooks/useIntersectionObserver'
import IconCard, { IconCardData } from '../IconCard'
import StatCardContent from './StatCardContent'
import GradientLogo from '../GradientLogoSvg'

const Stats = () => {
  const { t } = useTranslation()
  const { theme } = useTheme()
  const { observerRef } = useIntersectionObserver()


  const UsersCardData: IconCardData = {
    icon: <CommunityIcon color="primary" width="72px" />,
  }

  const TradesCardData: IconCardData = {
    icon: <SwapIcon color="primary" width="72px" />,
  }

  return (
    <>
      <Flex mb="48px" justifyContent="center" alignItems="center" flexDirection="column" ref={observerRef}>
        <GradientLogo height="48px" width="48px" mb="24px" />
        <Heading color='primary' textAlign="center" scale="xl">
          {t('Used by millions.')}
        </Heading>
        <Heading color='white' textAlign="center" scale="xl" mb="32px">
          {t('Trusted with billions.')}
        </Heading>
        <Text textAlign="center">
          Shaping the Future of Decentralized Trading:

        </Text>
        {/* <Flex flexWrap="wrap">
        <Text display="inline" textAlign="center" color="textSubtle" mb="20px">
          {entrusting}
          <>{tvl ? <>{tvlString}</> : <Skeleton display="inline-block" height={16} width={70} mt="2px" />}</>
          {inFunds}
        </Text>
      </Flex> */}

        <Text textAlign="center" bold mb="32px">
          BlockStar’s Unstoppable Expansion
        </Text>

        <Flex flexDirection={['column', null, null, 'row']}>
          {/* <IconCard {...UsersCardData} mr={[null, null, null, '16px']} mb={['16px', null, null, '0']}>
          <StatCardContent
            headingText={t('%users% users', { users })}
            bodyText={t('Punkswap Holders')}
            highlightColor={theme.colors.secondary}
          />
        </IconCard> */}
          {/* <IconCard {...TradesCardData} mr={[null, null, null, '16px']} mb={['16px', null, null, '0']}>
          <Flex
            minHeight={[null, null, null, '168px']}
            minWidth="332px"
            width="fit-content"
            flexDirection="column"
            justifyContent="flex-end"
            mt={[null, null, null, '64px']}
          >
            {isSmallerScreen && trades.length > 13 ? (
              <Heading scale="lg">{trades}%</Heading>
            ) : (
              <Heading scale="xl">{trades}%</Heading>
            )}
            <Heading color={theme.colors.secondary} scale="xl" mb="24px">
              {lpSymbol}
            </Heading>
            <Text color="textSubtle">APR in Farms and Pools</Text>
          </Flex>
        </IconCard> */}
          <IconCard {...UsersCardData}>
            <StatCardContent
              headingText={t('Safe and Secure')}
              bodyText={t('Trusted and secure platform')}
              highlightColor={theme.colors.primary}
            />
          </IconCard>
          <IconCard {...TradesCardData}>
            <StatCardContent
              headingText={t('Fast Transaction')}
              bodyText='On the BlockStar Chain'
              highlightColor={theme.colors.primary}
            />
          </IconCard>
        </Flex>

      </Flex>
    </>
  )
}

export default Stats
